import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SmartLabelIconCard } from './SmartLabelIconCard';
import './SmartLabel.scss'
import { CustomSimpleTooltip } from 'UI/atoms/Tooltips/Index';
import { ProgressBarScore } from 'UI/atoms/ProgressBarScore/ProgressBarScore';
import { CustomDataProp } from './smart-label.interface';

const UNKNOWN = 'unknown'

interface ISmartLabelCardDetail {
	id: any;
	color: string;
	value?: string | undefined;
	label: string | undefined;
	score: number | undefined;
	cardItems: any;
	articleMetrics: any;
	handleClicHide?: any;
	accountName?: string;
	customData?: CustomDataProp[];
}

export const SmartLabelCardDetail = (props: ISmartLabelCardDetail) => {
	const {
		id,
		color,
		value,
		label,
		score,
		cardItems,
		articleMetrics,
		handleClicHide,
		accountName,
		customData,
	} = props;
	const { t } = useTranslation();

	let environmentGroup;
	let equivalencesGroup;
	let savingsGroup;

	if (label === 'Planet') {
		environmentGroup = cardItems.filter(
			(item: any) => item.itemGroup === 'environment'
		);
		equivalencesGroup = cardItems.filter(
			(item: any) => item.itemGroup === 'equivalences'
		);
		savingsGroup = cardItems.filter(
			(item: any) => item.itemGroup === 'savings'
		);
	}


	let percent;
	let qualification;
	if (score !== undefined) {
		percent = ((Number(value) / score) * 100).toFixed(0);
		qualification = (Number(value) / score) * 10;
	}

	const validateProductTraceability = (product: string | [] | null) => {
		if (product === null || Array.isArray(product) && product.length === 0) {
			return 'not-applied'
		}

		return product
	}

	const manufacturing = articleMetrics['article_process_manufacturing_origin']
	const finishing = articleMetrics['article_process_finishing_origin_unique']
	const materialProcess = articleMetrics['article_process_matProcessing_origin_unique']

	const manufacturingCountry = validateProductTraceability(manufacturing)
	const finishingCountry = validateProductTraceability(finishing)
	const materialProcessCountry = validateProductTraceability(materialProcess)

	const showProductTraceabilityMap = articleMetrics['supplyMapDigitalization'] ?? false

	const traceabilityFacts = label === 'traceability-facts'

	const validateUnknown = (value: string) => {
		if (typeof value === 'string' && value.toLowerCase() === UNKNOWN) {
			return t('still-collection-data')
		}

		return t(value);
	}

	const validateReferenceType = () => articleMetrics['referenceType'] !== 'MATERIAL'

	return (
		<>
			{/* PEOPLE & TRANSPARENCY & CIRCULARITY */}
			{label !== 'eco-score' && (
				<div className="w-100 d-flex flex-column justify-content-between pb-3">
					<div className="w-100 d-flex flex-row justify-content-between">
						<span className="d-flex font-sfpro-medium letter-spacing-normal font-16px text-dark-100 mb-2 align-items-center">
							{t(`${label}`)}
						</span>
						<span
							id={id?.toLowerCase()}
							className="icon-icon-close font-sfpro-medium letter-spacing-normal font-10px"
							onClick={handleClicHide}
						></span>
					</div>
					<span
						className={`text-dark-60 font-sfpro-regular letter-spacing-normal ${traceabilityFacts ? '' : 'mb-3'}`}
						style={{
							paddingBottom: traceabilityFacts ? '30px' : ''
						}}
					>
						{t(`${label}-qr-description-inside`)}
					</span>

					{(label === 'Transparency' || traceabilityFacts) &&
					<>
						{cardItems.map((item: any) => (
							<SmartLabelIconCard
								key={item.id}
								icon={item.icon}
								value={articleMetrics[item.label]}
								color="#2C2C51"
								label={item.label}
								accountName={accountName}
								description={false}
								percent={item.id === "tracPeople"}
							/>
						))}
						{
							showProductTraceabilityMap &&
							<div className='d-flex flex-column my-2'>
								<div className='d-flex flex-row align-items-center'>
									<span className='text-dark-100 font-14px font-sfpro-medium'>{t('product-traceability')}</span>
									<div className='tooltipCustom d-flex'>
										<span className='icon-icon-help-circle font-14px ml-1 cursor-pointer'></span>
										<CustomSimpleTooltip position={{top: '20px', left: '-90%'}} maxWidth='200px' copy={t('product-traceability-tooltip')} />
									</div>
								</div>
								<div
									className='d-flex flex-column position-relative text-dark-100 px-2 my-2 product-traceability-container'
								>
									{(manufacturing && validateReferenceType()) && <DotElement text={t('manufacture')} country={validateUnknown(manufacturingCountry)} />}
									{(finishing && validateReferenceType()) && <DotElement text={t('supply-map-digitalization-finishing')} country={validateUnknown(finishingCountry)} />}
									{materialProcess && <DotElement text={t('materialProcess')} country={validateUnknown(materialProcessCountry)} />}
								</div>
							</div>
						}
					</>
					}
					{label === 'People' &&
						cardItems.map((item: any) => (
							<SmartLabelIconCard
								key={item.id}
								icon={item.icon}
								value={articleMetrics[item.label]}
								color="#2C2C51"
								label={item.label}
								accountName={accountName}
								description={false}
							/>
						))}
				</div>
			)}

			{/* PLANET */}
			{label === 'eco-score' && (
				<div className="w-100 d-flex flex-column justify-content-between pb-3">
					<div className="w-100 d-flex flex-row justify-content-between">
						<span className="d-flex font-sfpro-medium letter-spacing-normal font-16px text-dark-100 mb-2 align-items-center">
							{t(`${label}`)}
						</span>
						<span
							id={id?.toLowerCase()}
							className="icon-icon-close font-sfpro-medium letter-spacing-normal font-10px"
							onClick={handleClicHide}
						></span>
					</div>
					<div>
						{
							customData?.map(({label, score }: CustomDataProp) => (
								<div className='w-100 mb-2' key={label}>
									<span className='text-dark-100 font-sfpro-medium'>{ t(label) }</span>
									<ProgressBarScore
										color={color}
										value={articleMetrics[label] ?? 0}
										score={score}
										label={label}
										detail={t(`${label.toLowerCase()}-progress-bar-description`)}
									/>
								</div>
							))
						}
					</div>
				</div>
			)}
		</>
	);
};

const DotElement = ({text, country}: {text: string, country: string}) => (
	<div className='position-relative'>
		<div className='position-absolute item-container' />
		<div className='d-flex flex-column'>
			<span>{text}</span>
			<span className='ml-2 text-dark-60'>{country}</span>
		</div>
	</div>
)