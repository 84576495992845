import React, { MouseEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useProjects } from 'contexts/projectsContext';
import './TableRow.scss';
import PATHS from 'permissions/roles/AppPaths';
import ArticleDetail from '../ArticleDetail/index';
import MetricNumber from '../../atoms/MetricNumber';
import BodyText from 'UI/atoms/BodyText';
import ItemCopy from '../ItemCopy/Index';
import ItemCopyDownload from '../ItemCopyDownload/Index';
import ProgressBarDouble from 'UI/atoms/ProgressBarDouble/Index';
import { useTranslation } from 'react-i18next';
import Qualification from 'UI/atoms/Qualification';
import { SUSTAINABILITY_INDEX_TOTAL_SCORES } from 'utils/ProjectResults';
import { EDataTypes, EMetricNames } from 'UI/Template/Table/types/TableEnums';
import { SimpleTooltip, CustomTooltip } from 'UI/atoms/Tooltips/Index';
import { getLabelByPoints } from 'utils/IndexQualifications';
import { capitalize, formatDate, formatNumber, formatNumberNoDecimals, formatTagName, numberWithDots } from 'utils/utils';

import useRow from 'hooks/useRow';
import { useTable } from 'contexts/tableContext';
import BodyTextTooltip from 'UI/atoms/BodyText/BodyTextTooltip';
import { useAccount } from 'contexts/accountContext';
import ProgressBarSimple from 'UI/atoms/ProgressBarSimple/Index';
import {
	equivalenceByStaticColumns,
	staticsColums,
	SAVING_DEBTS_INDUSTRY_STANDARD
} from './types';
import { customData } from './customData';
import { AccountFeatureKeysProps } from 'hooks/useMenuState.hook';
import Notification from 'components/Elements/Notification';
import Favorites from 'services/storage/Favorites';
import { SelectFavorite } from 'UI/atoms/SelectFavorite';
import eventEmitter from 'utils/eventEmiter';

const EQUIVALENCE_VIEW = "equivalenceView";

const CELL_CLASSNAME = [
	'pl-2 font-sfpro-medium letter-spacing-normal text-capitalize font-14px text-overflow-ellipsis overflow-hidden',
	'pl-2 font-sfpro-regular letter-spacing-normal text-uppercase text-dark-60 font-12px text-overflow-ellipsis overflow-hidden',
	'icon-icon-eye d-none d-lg-block'
]

interface Props {
	history?: any;
	datas: any;
	subcolumns?: any;
	striped?: boolean | undefined;
	indexRow: number;
	pathname?: string;
	enableBenchmarkData: boolean;
	selectedView: any
}
const TableRow = (props: Props) => {
	const { setOpenDetailScopeModal, setItemSelected } = useTable();
	const {
		determinateProgressBarColor,
		determinateCompletionCategory,
		handleValueTypeText,
		handleColor,
		convertToString,
		standarizateWarehouse,
		blockByPlan,
		standarizateName,
	} = useRow();
	const { subcolumns, datas, striped, indexRow, enableBenchmarkData, selectedView } = props;
	const { name, reference, imageUrl, id } = props.datas;
	const { equivalencesList, openModalSaveChanges, setOpenModalSaveChanges } = useProjects();
	const { account } = useAccount();
	const h = useHistory();
	const { t } = useTranslation();
	const scores: any = SUSTAINABILITY_INDEX_TOTAL_SCORES;
	const localDatas = {
		...datas,
		...customData
	}

	const path = h.location.pathname;
	const handleClickRow = (event:any) => {
		if( openModalSaveChanges.customView ){
			setOpenModalSaveChanges({
				...openModalSaveChanges,
				open: true
			})
		}else{
			if (path.includes("plm")) {
				const cell = event.target as HTMLElement;
				if (!CELL_CLASSNAME.includes(cell.className)) {
					event.stopPropagation()
					setOpenDetailScopeModal(true)
					setItemSelected(localDatas)
					return
				}
			}
			if (path.includes('data-collection')) {
				event.stopPropagation();
				return
			}
			if (id && h) h.push(PATHS.articlesOnePage.replace(':idArticle', id));
		}
	};

	const handleModal = (e:any) => {
		e.stopPropagation();
		setOpenDetailScopeModal(true)
		setItemSelected(localDatas)
	}

	const QualificationCell = ({ cell, resultLabelCategory }: any) => {
		const [isTooltipOpen, setIsTooltipOpen] = useState(false);

		return (
			<>
				<div
					className="d-flex align-items-center"
					id={cell}
					onMouseEnter={() => setIsTooltipOpen(true)}
					onMouseLeave={() => setIsTooltipOpen(false)}
					style={{ width: 22 }}
				>
					<Qualification label={resultLabelCategory} />
				</div>
				<SimpleTooltip
					text={t(`score-${resultLabelCategory}-description`)}
					tooltipId={cell}
					tooltipOpen={isTooltipOpen}
					styles={{
						maxWidth: '250px',
						width: 'auto',
						textAlign: 'start',
					}}
				/>
			</>
		);
	};

	const validatePlm = () => path.includes('plm') ? localDatas['articleName'] : path.includes('data-collection') ? localDatas['name'] : name

	const handleShowBenchmarkData = (name:string) => {
		if (staticsColums.includes(name)) return true;
		return false;
	}

	const validateAccountFeature = (compareKey: string) => account && account.accountFeatureKeys?.some(({key}: AccountFeatureKeysProps) => key === compareKey)


	const [favorites, setFavorites] = useState<string[]>(Favorites.getCurrentfavorites());
	/**
	 * Save favorites
	 * @param string articleId
	 * @returns void
	 */
	const handleSaveFavorites = (e: MouseEvent<HTMLButtonElement>, articleId: string) => {
		e.stopPropagation();

		// emit event to update favorites
		eventEmitter.emit('updade_favorites', { update: true, timestamp: new Date().toLocaleTimeString()});

		const savedFavorites = Favorites.getCurrentfavorites();
		const isFavorite = savedFavorites.includes(articleId);
		if ( isFavorite ) {
			const newFavorites = savedFavorites.filter((item: string) => item !== articleId);
			const parseNewFavorites = JSON.stringify(newFavorites);
			Favorites.saveFavorites(parseNewFavorites);
			setFavorites(newFavorites);
			Notification.display("success", t("favorite-removed"))
			return;
		}

		savedFavorites.push(articleId);
		setFavorites(savedFavorites);
		const parseNewFavorites = JSON.stringify(savedFavorites);
		Favorites.saveFavorites(parseNewFavorites);
		Notification.display("success", t("favorite-added"))

	}

	return (
		<tr
			key={name}
			className={`cursor-pointer table-item ${striped ? 'striped' : ''}`}
			style={{ height: '53px' }}
			onClick={handleClickRow}
		>
			{subcolumns?.map((c: any, i: number) => {
				const metricValue = localDatas[c.metricName];
				const cell = `row-${indexRow}-column-${i}`;

					let value = metricValue;
					let equivalence = 0;
					let absolute = undefined;
					if (
						value !== null &&
						value !== undefined &&
						c.dataType === EDataTypes.ue
					) {
						equivalencesList.filter((eq: any) => {
							if (eq.keyName === c.defaultEq) equivalence = eq.amount;
						});
						value = metricValue * equivalence;
					}

				if (
					value !== null &&
					value !== undefined &&
					c.dataType === EDataTypes.pe
				) {
					equivalencesList.filter((eq: any) => {
						if (eq.keyName === c.defaultEq) equivalence = eq.amount;
					});
					value = metricValue * localDatas.amount * equivalence;
				}

				if (
					value !== null &&
					value !== undefined &&
					c.dataType === EDataTypes.p
				) {
					equivalencesList.filter((eq: any) => {
						if (eq.keyName === c.defaultEq) equivalence = eq.amount;
					});
					value = metricValue * localDatas.amount;
				}

					if (c.visualType === 'progressDouble') {
						const replaceMetricId = c.metricName.replace(
							'_Relative',
							''
						);
						absolute = localDatas[replaceMetricId];
					}

					const maxScore = scores[c.metricName];

					const percent = (value / maxScore) * 100;
					const resultLabelCategory = getLabelByPoints(
						(value / maxScore) * 10
					);

					const metricNameBenchmarkPercent = SAVING_DEBTS_INDUSTRY_STANDARD[c.id]

					const metricNameBenchmark = equivalenceByStaticColumns[c.id]

					const valueBenchmark = datas[metricNameBenchmarkPercent];

					const realValueBenchmark = datas[metricNameBenchmark];
					const tooltipValue = realValueBenchmark - value;

				const fixedViews = ['articleDetail', 'materialName', 'supplierName']

				let percentBenchmark = 0;

				if (valueBenchmark < 0) percentBenchmark = Math.abs(valueBenchmark);
				else percentBenchmark = valueBenchmark;

				const toggleStatus = valueBenchmark >= 0 ? {text:"savings", color: "#527800"} : {text: "debts", color: "#B44D49"}

				const toggleColumnsWidth = () => {
					if (c.visualType === "simpleBarWithText") {
						return '300px'
					}

					if (c.visualType === "folder") {
						return '50px';
					}

					if (selectedView.name === EQUIVALENCE_VIEW && selectedView.columns.filter((item: any) => item.metric.length > 1).length > 0) {
						return '200px';
					}

					return '135px';
				}

				return (
					<td
						key={c.id}
						className={`pt-1 pr-3 pb-1 pl-3 ${
							(fixedViews.includes(c.visualType)) && 'article-info'
						}`}
						style={{
							minWidth: toggleColumnsWidth(),
							maxWidth: toggleColumnsWidth()
						}}
					>
						<div className='d-flex flex-column align-items-start w-100'>
							<div className='d-flex flex-row align-items-center w-100'>
								{c.visualType === 'articleDetail' && (
									<ArticleDetail
										name={validatePlm()}
										reference={reference}
										imageUrl={imageUrl}
										id={id}
										path={path}
									/>
								)}
								{c.visualType === 'qrCodeUrl' && (
									<ItemCopyDownload
										copy={localDatas.qrCodeUrl}
										link={localDatas.qrCodeUrl}
										alt={'qr'}
										file={localDatas.qrCodeUrl}
										fileName={`qr-${localDatas.name}-${localDatas.reference}.svg`}
										fileType={'image/svg+xml'}
										width={'50px'}
										height={'50px'}
										icon={'download'}
									/>
								)}
								{c.visualType === 'smartlabelUrl' && (
									<ItemCopy
										copy={localDatas.smartlabelUrl}
										link={localDatas.smartlabelUrl}
										color={'100'}
									/>
								)}
								{c.visualType === 'soon' && (
									<BodyText copy={t('comingSoon')} color={'100'} />
								)}
								{c.visualType === 'text' && (
									<>{
										((
											c.metricName === 'Traceability_Certificates' ||
											c.metricName === 'supplier_certificate_names' ||
											c.metricName === 'material_certificate_names'
										) &&
										!validateAccountFeature('eco_score')
										? <BlockData />
										: <BodyText
											id={id}
											indicator={c.label}
											copy={handleValueTypeText(c.metricName, metricValue, localDatas)}
											color={handleColor(metricValue, localDatas) ? "40" : '100'}
										/>
									)}</>
								)}
								{c.visualType === 'progressSimple' && (
									<>
										<span className="text-dark-100 font-14px">{formatNumber((value / maxScore) * 10)}</span>
										<span className="font-sfpro-regular text-dark-60 font-14px ml-1"> {t("points").toLowerCase()}</span>
									</>
								)}
								{c.visualType === 'progressDouble' && (
									<ProgressBarDouble
										value={value}
										color={c.color}
										absolute={absolute}
										indicator={c.indicator}
										metricUnit={c.metricUnit}
										dataType={EDataTypes.u}
									/>
								)}
								{c.visualType === 'numeric' && (
									<MetricNumber
										key={`${c.metricName}-${c.dataType}`}
										id={c.metricName}
										value={
											c.isSaving && c.dataType === EDataTypes.r
												? value * 100
												: value
										}
										isInteger={
											c.dataType === EDataTypes.ue ||
											c.dataType === EDataTypes.pe ||
											c.metricName === EMetricNames.amount
										}
										dataType={c.dataType}
									/>
								)}
								{c.visualType === 'qualification' && (
									<QualificationCell
										cell={cell}
										resultLabelCategory={resultLabelCategory}
									/>
								)}
								{(handleShowBenchmarkData(c.id) && c.visualType !== "articleDetail" && enableBenchmarkData  && path.includes("lca")) && (
									<div
										className={`colors-ecoscore-${valueBenchmark >= 0 ? "A" : "E"}`}
										style={{
											width: 8,
											height: 8,
											borderRadius: "50%",
											marginLeft: 6
										}}
									/>
								)}
								{c.visualType === 'simpleBarWithText' && (
									<div className='d-flex flex-row align-items-center justify-content-start w-100' style={{ gap: '20px'}}>
										<ProgressBarSimple
											useColor={true}
											percent={localDatas.Traceability_total_suppliers_traced ? (localDatas.Traceability_total_suppliers_traced * 100) : 0}
											color={determinateProgressBarColor(localDatas.Traceability_total_suppliers_traced ? (localDatas.Traceability_total_suppliers_traced * 100) : 0)}
											articleSupplier={localDatas.Traceability_article_suppliers_traced ? (localDatas.Traceability_article_suppliers_traced * 100) : 0}
											materialSupplier={localDatas.Traceability_mat_suppliers_traced ? (localDatas.Traceability_mat_suppliers_traced * 100) : 0}
											rawMaterialSupplier={localDatas.Traceability_rmat_suppliers_traced ? (localDatas.Traceability_rmat_suppliers_traced * 100) : 0}
										/>
										<span
											role='traceabilityCategory'
											className='text-dark-100 font-14px'
										>{ determinateCompletionCategory(localDatas.Traceability_score, localDatas.Traceability_total_suppliers_traced) }</span>
									</div>
								)}
								{
									c.visualType === 'bestPractices' && (
										<>{
											!validateAccountFeature('eco_score')
											? <BlockData />
											: <BestPracticeIndicator
												data={metricValue}
												text={t(`best-practice${metricValue ? '' : '-not'}-declared`)}
											/>
										}</>
									)
								}
								{
									(c.visualType === "materialName" || c.visualType === "supplierName") && (
										<div className="d-flex flex-column text-overflow-ellipsis overflow-hidden mr-2" style={{ lineHeight: 1.6 }}>
											<span
												className="font-sfpro-medium letter-spacing-normal font-14px text-overflow-ellipsis overflow-hidden"
												style={{ lineHeight: 1.6 }}
											>{standarizateWarehouse(standarizateName(convertToString([datas[c.visualType]])))}</span>
											{
												localDatas['material_reference'] &&
												<span className="font-sfpro-regular letter-spacing-normal text-uppercase text-dark-60 font-12px text-overflow-ellipsis overflow-hidden" style={{ lineHeight: 1.6 }}>{localDatas['material_reference']}</span>
											}
										</div>
								)}
								{(
									c.visualType === "text_tooltip" && (
										<BodyTextTooltip
											id={id}
											indicator={c.label}
											copy={metricValue}
											color={'100'}
											tooltipText={
												localDatas['origin'] && localDatas['origin']['name']
											}
										/>
									)
								)}
								{(
									c.visualType === "link" && (
										<a className={`text-yellow text-yellow-underline ${!metricValue ? 'non-click' : ''}`} href={metricValue ?? '#'} target='_blank' rel='noreferrer'>
											<span className='text-yellow'>{ t('data-collection-form') }</span>
											<span className='text-yellow icon-icon-new-window ml-2'></span>
										</a>
									)
								)}
								{(
									c.visualType === "tag" && (
										<div className={`rounded-20 px-2 py-1 text-center tag-cell--${formatTagName(metricValue ?? 'pending')}`} style={{ minWidth: 120 }}>
											{metricValue ? metricValue : t('pending')}
										</div>
									)
								)}
								{(
									c.visualType === 'date' && (
										<div>{metricValue ? formatDate(new Date(metricValue)) : <span className='text-dark-40'>{ t('pending') }</span>}</div>
									)
								)}
								{(
									c.visualType === 'referenceType' && (
										<BodyText
											id={id}
											indicator={c.label}
											copy={capitalize(metricValue ?? 'ARTICLE')}
											color={'100'}
										/>
									)
								)}
								{(
									c.visualType === 'percent' && (
										<BodyText
											id={id}
											indicator={c.label}
											copy={`${formatNumberNoDecimals(metricValue)}%`}
											color={'100'}
										/>
									)
								)}
								{(
									c.visualType === 'folder' && (
										<a
											className={`d-flex justify-content-end w-100 px-2 ${!metricValue ? 'non-click' : ''}`}
											href={metricValue}
											target='_blank'
											rel='noreferrer'
											style={{
												textDecoration: 'none'
											}}
										>
											<span className={`icon-icon-projects font-18px text-dark-60 text-dark-100-hover ${!metricValue ? 'non-click' : ''}`}></span>
										</a>
									)
								)}
								{(
									c.visualType === 'utility' && (
										<BodyText
											id={id}
											indicator={c.label}
											copy={t("utility-cell", {value: formatNumber(metricValue), total: 1})}
											color={'100'}
										/>
									)
								)}
								{(
									c.visualType === 'amountArticle' && (
										<MetricNumber
											key={`${c.metricName}-${c.dataType}`}
											id={c.metricName}
											value={localDatas['amount']}
											isInteger={true}
											dataType={c.dataType}
										/>
									)
								)}
							</div>
							{(handleShowBenchmarkData(c.id) && c.visualType !== "articleDetail" && enableBenchmarkData && path.includes("lca")) && (
								<div className='tooltipCustom tooltips-lca-benchmark d-flex flex-row align-items-center font-12px'>
									{
										(typeof valueBenchmark !== "number" && !valueBenchmark)
										? 	<AskUpdate text={t('ask-for-update')} />
										: 	<>
												<span className='text-dark-60'>{ `${formatNumberNoDecimals(percentBenchmark * 100)} %`}</span>
												<span
													className='pl-1 font-sfpro-medium'
													style={{
														color: toggleStatus.color
													}}
												>{ t(`lca-benchmark-${toggleStatus.text}`) }</span>
												{
													<CustomTooltip
														metric={realValueBenchmark}
														absolute={tooltipValue}
														extraCss={'tooltiptext-left'}
														indicator={c.indicator}
														metricUnit={c.metricUnit}
														dataType={c.dataType}
														status={toggleStatus}
													/>
												}
											</>
									}
								</div>
							)}
							{(
								c.visualType === 'saved' && (
									<SelectFavorite select={Favorites.getCurrentfavorites().includes(localDatas.id)} onClick={(e) => handleSaveFavorites(e, localDatas.id)} />
								)
							)}
						</div>
						{
							(enableBenchmarkData && path.includes("lca") && handleShowBenchmarkData(c.id)) ? (
								<>
									{
										c.visualType === "articleDetail" ? (
											<div className='d-flex flex-row align-items-center font-sfpro-medium font-12px py-2 my-1'>
												<span>{t("industry-standard")}</span>
												<IndustryStandard indexRow={indexRow} i={i} />
											</div>
										) : (
											<div className='py-2 my-1' style={{ borderTop: "1px solid #D6D6D7" }}>
												{
													!realValueBenchmark
													? <AskUpdate text={t('ask-for-update')} />
													: (c.dataType === EDataTypes.ue || c.dataType === EDataTypes.pe)
													? (numberWithDots(formatNumberNoDecimals(realValueBenchmark)))
													: (numberWithDots(formatNumber(realValueBenchmark)))
												}
											</div>
										)
									}
								</>
							) : <div></div>
						}
					</td>
				);
			})}
			{
				path.includes('plm') && (
					<>
						<td>
							<span
								onClick={handleModal}
								data-testid="arrow-icon"
								className='icon-icon-chevron-right font-30px mx-2 text-dark-60'
							/>
						</td>
					</>
				)
			}
		</tr>
	);
};

const IndustryStandard = ({indexRow, i}: {indexRow: number, i: number}) => {
	const tooltipIdIndustryStandard = `row-${indexRow}-column-${i}-industry-standard`;
	const [openTooltipIndustryStandard, setOpenTooltipIndustryStandard] = useState(false);
	const { t } = useTranslation();

	const handleOpenTooltipIndustryStandard = (value: boolean) => () => setOpenTooltipIndustryStandard(value);

	return (
		<>
			<span
				id={tooltipIdIndustryStandard}
				className='icon-icon-info d-flex ml-1'
				onMouseEnter={handleOpenTooltipIndustryStandard(true)}
				onMouseLeave={handleOpenTooltipIndustryStandard(false)}
				/>
			<SimpleTooltip
				tooltipId={tooltipIdIndustryStandard}
				tooltipOpen={openTooltipIndustryStandard}
				text={t('benchmark-industry-standard-tooltip')}
				styles={{
					width: '300px',
					textAlign: 'start',
				}}
				/>
		</>
	)
}

interface BestPracticeIndicatorInterface {
	data: boolean,
	text: string,
	modal?: boolean
}

export const BestPracticeIndicator = ({data, text, modal = false}: BestPracticeIndicatorInterface) => (
	<div className='d-flex flex-row align-items-center' style={{
		minWidth: '112px'
	}}>
		<div
			className={`mr-2 colors-ecoscore-${data ? 'A' : 'E'}`}
			style={{
				width: 8,
				height: 8,
				borderRadius: '50%'
			}}
		/>
		<span className={`font-14px text-dark-${modal ? '60' : '100'}`}>{text}</span>
	</div>
)

export const BlockData = ({color = '40'}: {color?: string}) => (
	<span className={`icon-icon-lock text-dark-${color}`} />
)

const AskUpdate = ({text}: {text: string}) => (
	<div className='ask-update'>
		<img src="/img/update-icon.svg" alt="Update icon" />
		<span className='text-dark-60 font-12px'>{ text }</span>
	</div>
)

export default TableRow;
