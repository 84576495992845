export enum ESchemasIds {
    indicators = 'indicators',
    stages = 'stages',
    diagnosis = 'diagnosis',
    sustainabilityindex = 'sustainabilityindex',
    traceability = 'traceability',
    smartlabels = 'smartlabels',
    benchmark = 'benchmark'
}

export enum EProductViewsIds {
	lca = 'lca',
    slca = 'slca', // product not designed yet
    lcc = 'lcc', // product not designed yet
    mci = 'mci', // product not designed yet
    sustainabilityindex = 'sustainabilityindex',
    benchmark = 'benchmark',
    smartlabels = 'smartlabels',
    ecommerce = 'ecommerce',
    onepage = 'onepage',
}

export enum EViewVariantsIds {
    globalView = 'globalView',
    waterUseViewLca = 'waterUseViewLca',
    globalWarmingViewLca = 'globalWarmingViewLca',
    eutrophicationViewLca = 'eutrophicationViewLca',
    resourceUseViewLca = 'resourceUseViewLca',
    rawMaterialViewLca = 'rawMaterialViewLca',
    materialProcessViewLca = 'materialProcessViewLca',
    materialWetProcessViewLca = 'materialWetProcessViewLca',
    articleWetProcessViewLca = 'articleWetProcessViewLca',
    manufacturingViewLca = 'manufacturingViewLca',
    transportViewLca = 'transportViewLca',
    packagingViewLca = 'packagingViewLca',
}

export enum EViewGroups {
    global = 'global',
    indicators = 'indicators',
    stages = 'stages',
    evaluation = 'evaluation',
    planet = 'planet',
    people = 'people',
    transparency = 'transparency',
    circularity = 'circularity',
}

export enum ETableIds {
    indicators = 'indicators',
    stages = 'stages',
    evaluation = 'evaluation',
    traceability = 'traceability',
}

export enum ETableTypes {
	pagination = 'pagination',
	infinite ='infinite'
}

export enum ESustainibilityIndex {
    checklist = 'checklist',
    planet = 'planet',
    people = 'people',
    transparency = 'transparency',
    circularity = 'circularity',
}

export enum ETraceability {
    checklist = 'checklist',
    people = 'people',
    suppliers = 'suppliers',
    process = 'process',
    transportChain = 'transportChain',
    certificates = 'certificates',
}
// Enum for traceability article scope
export enum ETraceabilityScopes {
    articleSummary = 'articleSummary',
    completion = 'completion',
    saved = 'saved',
    supplierDescription = 'supplierDescription',
    logistics = 'logistics',
    certificateLists = 'certificateLists',
    materialDescription = 'materialDescription',
    materialSupply = 'materialSupply',
    material_certificate_names = 'material_certificate_names',
    logisticDescription = 'logisticDescription',
    packagingDescription = 'packagingDescription',
    bestPractices = 'bestPractices'
}

export enum EDataCollection {
    projectDescription = 'projectDescription',
    dataRecords = 'dataRecords',
}

export enum ECEI {
    articleSummary = 'articleSummary',
    overview = 'overview',
    feedstock = 'feedstock',
    waste = 'waste',
    usage_potential = 'usage_potential',
}

export enum EIndicators {
    checklist = 'checklist',
    waterUse = 'waterUse',
    climateChange = 'climateChange',
    eutrophication = 'eutrophication',
    fossilFuels = 'fossilFuels',
    allIndicators = 'allIndicators',
    articleSummary = 'articleSummary',
    completion = 'completion',
    supplierDescription = 'supplierDescription',
    logistics = 'logistics',
    certificateLists = 'certificateLists',
    bestPractices = 'bestPractices',
    materialDescription = 'materialDescription',
    materialSupply = 'materialSupply',
    material_certificate_names = 'material_certificate_names',
    logisticDescription = 'logisticDescription',
    packagingDescription = 'packagingDescription',

    projectDescription = 'projectDescription',
    dataRecords = 'dataRecords',
    // cei
    overview = 'overview',
    feedstock = 'feedstock',
    waste = 'waste',
    usage_potential = 'usage_potential',
    saved = 'saved',
}

export enum EStages {
    checklist = 'checklist',
    total = 'total',
    rawMaterials = 'rawMaterials',
    materialProcess = 'materialProcess',
    materialWetProcess = 'materialWetProcess',
    articleWetProcess = 'articleWetProcess',
    manufacture = 'manufacture',
    packaging = 'packaging',
    transport = 'transport',
    article = 'article',
    articleSummary = 'articleSummary',
    completion = 'completion',
    saved = 'saved',
    supplierDescription = 'supplierDescription',
    logistics = 'logistics',
    certificateLists = 'certificateLists',
    bestPractices = 'bestPractices',
    materialDescription = 'materialDescription',
    materialSupply = 'materialSupply',
    material_certificate_names = 'material_certificate_names',
    logisticDescription = 'logisticDescription',
    packagingDescription = 'packagingDescription',

    projectDescription = 'projectDescription',
    dataRecords = 'dataRecords',
    // cei
    overview = 'overview',
    feedstock = 'feedstock',
    waste = 'waste',
    usage_potential = 'usage_potential',
}

export enum ESmartlabels {
    views = 'views',
    smartlabels = 'smartlabels',
    qrcode = 'qrcode',
    saved = 'saved',
}


export enum EDataTypes {
    u = 'u', // Unitary impact (benchmark),
    p = 'p', // Production impact (benchmark),

	ue = 'ue', //'unitaryEquivalence',
    pe = 'pe', //'productionEquivalence',

    r = 'r', // Benchmark relative impact (units)
    bar = 'bar', // Benchmark relative impact (%) - progressbar

    si = 'si', // sustainabilityIndex,
    tr = 'tr', // traceability,

    i = 'i', // information
}

export enum EMetricUnitTypes {
    waterUse = 'lH2O',
    climateChange = 'kgCO2',
    eutrophication = 'gPO4',
    fossilFuels = 'mj',

    glasses = 'waterGlasses',
    tomatoes = 'tomatoes',
    carRefueling = 'carRefueling',
    battery = 'battery',
    hairdryer = 'hairdryer',

    bottles = "waterGlasses", // New: bottles = 'bottles',
    lightBulbs = 'ligthBulbs',
    washingMachine = 'washingMachine',
    carTravelling = 'carTravelling',

    pool = 'pool',
    electricity = 'electricity',
    detergent = 'detergent',
    planeTravelling = 'planeTravelling',

    waterIntake = 'waterIntake',
    tree = 'tree',
    fish = 'fish',
    carNotTravelled = 'carNotTravelled',

    waterIntakeYear = 'waterIntakeYear',
    plantedTrees = 'plantedTrees',
    divingCylinder = 'divingCylinder',
    oxygen = 'oxygen',
    planeNotTravelled = 'planeNotTravelled',

    tracCertificates = 'tracCertificates',
    tracKm = 'tracKm',
    tracPeople = 'tracPeople',
    tracProcess = 'tracProcess',
    tracSuppliers = 'tracSuppliers',
    tracCategory = 'tracCategory',
    tracUnits = 'tracUnits',
    tracMaterials = 'tracMaterials',
    tracCompetion = 'tracCompetion',
    tracTierCategory = 'tracTierCategory',
    tracOrigin = 'tracOrigin',
    tracStage = 'tracStage',
    tracShippingTransport = 'tracShippingTransport',
    tracCertNames = 'tracCertNames',
    tracBestPracticeDeclared = 'tracBestPracticeDeclared',
    tracBestPracticeNotDeclared = 'tracBestPracticeNotDeclared',

    sustainabilityindex = 'sustainabilityindex',
    checklist = 'checklist',

    tracMCategory = 'tracMCategory',
    tracMComposition = 'tracMComposition',
    tracMWProcess = 'tracMWProcess',
    tracMProcess = 'tracMProcess',
    tracRMExtraction = 'tracRMExtraction',
    tracMCertificate = 'tracMCertificate',

    tracWarehouseOrigin = 'tracWarehouseOrigin',
    tracMTransport = 'tracMTransport',
    tracMOrigin = 'tracMOrigin',
    tracPrimaryRetail = 'tracPrimaryRetail',
    tracSecondaryWarehouse = 'tracSecondaryWarehouse',
    tracTerciaryShipping = 'tracTerciaryShipping',

    projectName = 'projectName',
    totalRef = 'totalRef',
    refType = 'refType',
    formUrl = 'formUrl',
    status = 'status',
    createDate = 'createDate',
    closingDate = 'closingDate',
    link = 'link',
    mci_score = 'mci_score',
    recycled_content = 'recycled_content',
    total_waste = 'total_waste',
    utility = 'utility',
    saved = 'saved',
}

export enum EMetricNames {
    Checklist = 'checklist',
    name = 'name', // ArticleInfo o name?
    Amount = 'amount',
    amountArticle = 'amountArticle',
    Collection = 'collection',
    Weight = 'weight',
    Category = 'category',
    Color = 'color',

    scans = 'scans',
    smartlabelUrl = 'smartlabelUrl',
    qrCodeUrl = 'qrCodeUrl',

    articleName = 'articleName',
    materialName = 'materialName',
    supplierName = 'supplierName',

    Planet = 'Planet',
    People = 'People',
    Transparency = 'Transparency',
    Circularity = 'Circularity',
    Planet_Score = 'Planet_Score',
    People_Score = 'People_Score',
    Transparency_Score = 'Transparency_Score',
    Circularity_Score = 'Circularity_Score',
    Planet_Qualification = 'Planet_Qualification',
    People_Qualification = 'People_Qualification',
    Transparency_Qualification = 'Transparency_Qualification',
    Circularity_Qualification = 'Circularity_Qualification',
    Planet_Points = 'Planet_Points',
    People_Points = 'People_Points',
    Transparency_Points = 'Transparency_Points',
    Circularity_Points = 'Circularity_Points',

    Supplier_Planet = 'Supplier_Planet',
    Supplier_People = 'Supplier_People',
    Supplier_Transparency = 'Supplier_Transparency',
    Supplier_Circularity = 'Supplier_Circularity',
    Material_Planet = 'Material_Planet',
    Material_People = 'Material_People',
    Material_Transparency = 'Material_Transparency',
    Material_Circularity = 'Material_Circularity',
    Corporate_Planet = 'Corporate_Planet',
    Corporate_People = 'Corporate_People',
    Corporate_Transparency = 'Corporate_Transparency',
    Corporate_Circularity = 'Corporate_Circularity',

    Water = 'Water',
    ClimateChange = 'ClimateChange',
    Eutrophication = 'Eutrophication',
    Waste = 'Waste',
    WaterTotal = 'WaterTotal',
    FossilFuels = 'FossilFuels',
    ClimateChangeTotal = 'ClimateChangeTotal',
    EutrophicationTotal = 'EutrophicationTotal',
    WasteTotal = 'WasteTotal',
    FossilFuelsTotal = 'FossilFuelsTotal',
    RawMaterial_Water = 'RawMaterial_Water',
    RawMaterial_ClimateChange = 'RawMaterial_ClimateChange',
    RawMaterial_Eutrophication = 'RawMaterial_Eutrophication',
    RawMaterial_Waste = 'RawMaterial_Waste',
    RawMaterial_FossilFuels = 'RawMaterial_FossilFuels',
    MaterialProc_Water = 'MaterialProc_Water',
    MaterialProc_ClimateChange = 'MaterialProc_ClimateChange',
    MaterialProc_Eutrophication = 'MaterialProc_Eutrophication',
    MaterialProc_Waste = 'MaterialProc_Waste',
    MaterialProc_FossilFuels = 'MaterialProc_FossilFuels',
    MaterialDFP_Water = 'MaterialDFP_Water',
    MaterialDFP_ClimateChange = 'MaterialDFP_ClimateChange',
    MaterialDFP_Eutrophication = 'MaterialDFP_Eutrophication',
    MaterialDFP_Waste = 'MaterialDFP_Waste',
    MaterialDFP_FossilFuels = 'MaterialDFP_FossilFuels',
    ArticleDFP_Water = 'ArticleDFP_Water',
    ArticleDFP_ClimateChange = 'ArticleDFP_ClimateChange',
    ArticleDFP_Eutrophication = 'ArticleDFP_Eutrophication',
    ArticleDFP_Waste = 'ArticleDFP_Waste',
    ArticleDFP_FossilFuels = 'ArticleDFP_FossilFuels',
    Manufacture_Water = 'Manufacture_Water',
    Manufacture_ClimateChange = 'Manufacture_ClimateChange',
    Manufacture_Eutrophication = 'Manufacture_Eutrophication',
    Manufacture_Waste = 'Manufacture_Waste',
    Manufacture_FossilFuels = 'Manufacture_FossilFuels',
    Transport_Water = 'Transport_Water',
    Transport_ClimateChange = 'Transport_ClimateChange',
    Transport_Eutrophication = 'Transport_Eutrophication',
    Transport_Waste = 'Transport_Waste',
    Transport_FossilFuels = 'Transport_FossilFuels',
    LaDP_FossilFuels= 'LaDP_FossilFuels',
    LaDP_Water = 'LaDP_Water',
    LaDP_ClimateChange = 'LaDP_ClimateChange',
    LaDP_Eutrophication = 'LaDP_Eutrophication',
    LaDP_Waste = 'LaDP_Waste',

    RawMaterial_Water_Total = 'RawMaterial_Water_Total',
    MaterialProc_Water_Total = 'MaterialProc_Water_Total',
    MaterialDFP_Water_Total = 'MaterialDFP_Water_Total',
    ArticleDFP_Water_Total = 'ArticleDFP_Water_Total',
    Manufacture_Water_Total = 'Manufacture_Water_Total',
    Transport_Water_Total = 'Transport_Water_Total',
    LaDP_Water_Total = 'LaDP_Water_Total',

    RawMaterial_ClimateChange_Total = 'RawMaterial_ClimateChange_Total',
    MaterialProc_ClimateChange_Total = 'MaterialProc_ClimateChange_Total',
    MaterialDFP_ClimateChange_Total = 'MaterialDFP_ClimateChange_Total',
    ArticleDFP_ClimateChange_Total = 'ArticleDFP_ClimateChange_Total',
    Manufacture_ClimateChange_Total = 'Manufacture_ClimateChange_Total',
    Transport_ClimateChange_Total = 'Transport_ClimateChange_Total',
    LaDP_ClimateChange_Total = 'LaDP_ClimateChange_Total',

    RawMaterial_Eutrophication_Total = 'RawMaterial_Eutrophication_Total',
    MaterialProc_Eutrophication_Total = 'MaterialProc_Eutrophication_Total',
    MaterialDFP_Eutrophication_Total = 'MaterialDFP_Eutrophication_Total',
    ArticleDFP_Eutrophication_Total = 'ArticleDFP_Eutrophication_Total',
    Manufacture_Eutrophication_Total = 'Manufacture_Eutrophication_Total',
    Transport_Eutrophication_Total = 'Transport_Eutrophication_Total',
    LaDP_Eutrophication_Total = 'LaDP_Eutrophication_Total',

    RawMaterial_FossilFuels_Total = 'RawMaterial_FossilFuels_Total',
    MaterialProc_FossilFuels_Total = 'MaterialProc_FossilFuels_Total',
    MaterialDFP_FossilFuels_Total = 'MaterialDFP_FossilFuels_Total',
    ArticleDFP_FossilFuels_Total = 'ArticleDFP_FossilFuels_Total',
    Manufacture_FossilFuels_Total = 'Manufacture_FossilFuels_Total',
    Transport_FossilFuels_Total = 'Transport_FossilFuels_Total',
    LaDP_FossilFuels_Total = 'LaDP_FossilFuels_Total',

    Equivalences_Water_Total = 'Equivalences_Water_Total',
    Equivalences_ClimateChange_Total = 'Equivalences_ClimateChange_Total',
    Equivalences_Eutrophication_Total = 'Equivalences_Eutrophication_Total',
    Equivalences_Waste_Total = 'Equivalences_Waste_Total',
    Equivalences_FossilFuels_Total = 'Equivalences_FossilFuels_Total',

    Equivalences_Water_Relative = 'Equivalences_Water_Relative',
    Equivalences_ClimateChange_Relative = 'Equivalences_ClimateChange_Relative',
    Equivalences_Eutrophication_Relative = 'Equivalences_Eutrophication_Relative',
    Equivalences_Waste_Relative = 'Equivalences_Waste_Relative',
    Equivalences_FossilFuels_Relative = 'Equivalences_FossilFuels_Relative',

    Equivalences_RawMaterial_Water_Relative = 'Equivalences_RawMaterial_Water_Relative',
    Equivalences_MaterialProc_Water_Relative = 'Equivalences_MaterialProc_Water_Relative',
    Equivalences_MaterialDFP_Water_Relative = 'Equivalences_MaterialDFP_Water_Relative',
    Equivalences_ArticleDFP_Water_Relative = 'Equivalences_ArticleDFP_Water_Relative',
    Equivalences_Manufacture_Water_Relative = 'Equivalences_Manufacture_Water_Relative',
    Equivalences_Transport_Water_Relative = 'Equivalences_Transport_Water_Relative',
    Equivalences_LaDP_Water_Relative = 'Equivalences_LaDP_Water_Relative',

    Equivalences_RawMaterial_Water_Total = 'Equivalences_RawMaterial_Water_Total',
    Equivalences_MaterialProc_Water_Total = 'Equivalences_MaterialProc_Water_Total',
    Equivalences_MaterialDFP_Water_Total = 'Equivalences_MaterialDFP_Water_Total',
    Equivalences_ArticleDFP_Water_Total = 'Equivalences_ArticleDFP_Water_Total',
    Equivalences_Manufacture_Water_Total = 'Equivalences_Manufacture_Water_Total',
    Equivalences_Transport_Water_Total = 'Equivalences_Transport_Water_Total',
    Equivalences_LaDP_Water_Total = 'Equivalences_LaDP_Water_Total',

    Equivalences_RawMaterial_ClimateChange_Relative = 'Equivalences_RawMaterial_ClimateChange_Relative',
    Equivalences_MaterialProc_ClimateChange_Relative = 'Equivalences_MaterialProc_ClimateChange_Relative',
    Equivalences_MaterialDFP_ClimateChange_Relative = 'Equivalences_MaterialDFP_ClimateChange_Relative',
    Equivalences_ArticleDFP_ClimateChange_Relative = 'Equivalences_ArticleDFP_ClimateChange_Relative',
    Equivalences_Manufacture_ClimateChange_Relative = 'Equivalences_Manufacture_ClimateChange_Relative',
    Equivalences_Transport_ClimateChange_Relative = 'Equivalences_Transport_ClimateChange_Relative',
    Equivalences_LaDP_ClimateChange_Relative = 'Equivalences_LaDP_ClimateChange_Relative',

    Equivalences_RawMaterial_ClimateChange_Total = 'Equivalences_RawMaterial_ClimateChange_Total',
    Equivalences_MaterialProc_ClimateChange_Total = 'Equivalences_MaterialProc_ClimateChange_Total',
    Equivalences_MaterialDFP_ClimateChange_Total = 'Equivalences_MaterialDFP_ClimateChange_Total',
    Equivalences_ArticleDFP_ClimateChange_Total = 'Equivalences_ArticleDFP_ClimateChange_Total',
    Equivalences_Manufacture_ClimateChange_Total = 'Equivalences_Manufacture_ClimateChange_Total',
    Equivalences_Transport_ClimateChange_Total = 'Equivalences_Transport_ClimateChange_Total',
    Equivalences_LaDP_ClimateChange_Total = 'Equivalences_LaDP_ClimateChange_Total',

    Equivalences_RawMaterial_Eutrophication_Relative = 'Equivalences_RawMaterial_Eutrophication_Relative',
    Equivalences_MaterialProc_Eutrophication_Relative = 'Equivalences_MaterialProc_Eutrophication_Relative',
    Equivalences_MaterialDFP_Eutrophication_Relative = 'Equivalences_MaterialDFP_Eutrophication_Relative',
    Equivalences_ArticleDFP_Eutrophication_Relative = 'Equivalences_ArticleDFP_Eutrophication_Relative',
    Equivalences_Manufacture_Eutrophication_Relative = 'Equivalences_Manufacture_Eutrophication_Relative',
    Equivalences_Transport_Eutrophication_Relative = 'Equivalences_Transport_Eutrophication_Relative',
    Equivalences_LaDP_Eutrophication_Relative = 'Equivalences_LaDP_Eutrophication_Relative',

    Equivalences_RawMaterial_Eutrophication_Total = 'Equivalences_RawMaterial_Eutrophication_Total',
    Equivalences_MaterialProc_Eutrophication_Total = 'Equivalences_MaterialProc_Eutrophication_Total',
    Equivalences_MaterialDFP_Eutrophication_Total = 'Equivalences_MaterialDFP_Eutrophication_Total',
    Equivalences_ArticleDFP_Eutrophication_Total = 'Equivalences_ArticleDFP_Eutrophication_Total',
    Equivalences_Manufacture_Eutrophication_Total = 'Equivalences_Manufacture_Eutrophication_Total',
    Equivalences_Transport_Eutrophication_Total = 'Equivalences_Transport_Eutrophication_Total',
    Equivalences_LaDP_Eutrophication_Total = 'Equivalences_LaDP_Eutrophication_Total',

    Equivalences_RawMaterial_FossilFuels_Relative = 'Equivalences_RawMaterial_FossilFuels_Relative',
    Equivalences_MaterialProc_FossilFuels_Relative = 'Equivalences_MaterialProc_FossilFuels_Relative',
    Equivalences_MaterialDFP_FossilFuels_Relative = 'Equivalences_MaterialDFP_FossilFuels_Relative',
    Equivalences_ArticleDFP_FossilFuels_Relative = 'Equivalences_ArticleDFP_FossilFuels_Relative',
    Equivalences_Manufacture_FossilFuels_Relative = 'Equivalences_Manufacture_FossilFuels_Relative',
    Equivalences_Transport_FossilFuels_Relative = 'Equivalences_Transport_FossilFuels_Relative',
    Equivalences_LaDP_FossilFuels_Relative = 'Equivalences_LaDP_FossilFuels_Relative',

    Equivalences_RawMaterial_FossilFuels_Total = 'Equivalences_RawMaterial_FossilFuels_Total',
    Equivalences_MaterialProc_FossilFuels_Total = 'Equivalences_MaterialProc_FossilFuels_Total',
    Equivalences_MaterialDFP_FossilFuels_Total = 'Equivalences_MaterialDFP_FossilFuels_Total',
    Equivalences_ArticleDFP_FossilFuels_Total = 'Equivalences_ArticleDFP_FossilFuels_Total',
    Equivalences_Manufacture_FossilFuels_Total = 'Equivalences_Manufacture_FossilFuels_Total',
    Equivalences_Transport_FossilFuels_Total = 'Equivalences_Transport_FossilFuels_Total',
    Equivalences_LaDP_FossilFuels_Total = 'Equivalences_LaDP_FossilFuels_Total',

    Savings_Water = 'Savings_Water',
    Savings_ClimateChange = 'Savings_ClimateChange',
    Savings_Eutrophication = 'Savings_Eutrophication',
    Savings_Waste = 'Savings_Waste',
    Savings_FossilFuels = 'Savings_FossilFuels',

    Savings_Water_Relative = 'Savings_Water_Relative',
    Savings_ClimateChange_Relative = 'Savings_ClimateChange_Relative',
    Savings_Eutrophication_Relative = 'Savings_Eutrophication_Relative',
    Savings_Waste_Relative = 'Savings_Waste_Relative',
    Savings_FossilFuels_Relative = 'Savings_FossilFuels_Relative',

    Savings_Water_Relative_Numeric = 'Savings_Water_Relative_Numeric',
    Savings_ClimateChange_Relative_Numeric = 'Savings_ClimateChange_Relative_Numeric',
    Savings_Eutrophication_Relative_Numeric = 'Savings_Eutrophication_Relative_Numeric',
    Savings_FossilFuels_Relative_Numeric = 'Savings_FossilFuels_Relative_Numeric',

    Savings_Water_Total = 'Savings_Water_Total',
    Savings_ClimateChange_Total = 'Savings_ClimateChange_Total',
    Savings_Eutrophication_Total = 'Savings_Eutrophication_Total',
    Savings_FossilFuels_Total = 'Savings_FossilFuels_Total',

    Equivalence_Savings_Water = 'Equivalence_Savings_Water',
    Equivalence_Savings_ClimateChange = 'Equivalence_Savings_ClimateChange',
    Equivalence_Savings_Eutrophication = 'Equivalence_Savings_Eutrophication',
    Equivalence_Savings_FossilFuels = 'Equivalence_Savings_FossilFuels',
    Equivalence_Savings_Water_Total = 'Equivalence_Savings_Water_Total',
    Equivalence_Savings_ClimateChange_Total = 'Equivalence_Savings_ClimateChange_Total',
    Equivalence_Savings_Eutrophication_Total = 'Equivalence_Savings_Eutrophication_Total',
    Equivalence_Savings_FossilFuels_Total = 'Equivalence_Savings_FossilFuels_Total',

    // Traceability
    Traceability_Checklist = 'Traceability_Checklist',
    category = 'category',
    amount = 'amount',
    articleMaterial = 'articleMaterial',
    totalArticleMaterial = 'totalArticleMaterial',
    Traceability_Suppliers = 'Traceability_Suppliers',
    Traceability_Certificates = 'Traceability_Certificates',


    Traceability_People = 'Traceability_People',
    Traceability_Process = 'Traceability_Process',
    Traceability_Km = 'Traceability_Km',
    Traceability_People_Total = 'Traceability_People_Total',
    Traceability_Suppliers_Total = 'Traceability_Suppliers_Total',
    Traceability_Process_Total = 'Traceability_Process_Total',
    Traceability_Km_Total = 'Traceability_Km_Total',

    Traceability_Completion = 'Supply_Chain_Knowledge',
    Traceability_Completion_Category = 'Supply_Chain_Category',

    supplier_tiers = 'supplier_tiers',
    supplier_origin_abbreviation = 'supplier_origin_abbreviation',
    categoryId = 'categoryId',
    transport = 'transport',
    supplier_transport_category = 'supplier_transport_category',
    supplier_certificate_names = 'supplier_certificate_names',
    supplier_closedLoopWaterSystem_validation = 'supplier_closedLoopWaterSystem_validation',
    supplier_renewableEnergy_validation = 'supplier_renewableEnergy_validation',

    material_category_names = 'material_category_names',
    material_process_extraction_relativeAmounts = 'material_process_extraction_relativeAmounts',
    material_process_finishing_supplier_names = 'material_process_finishing_supplier_names',
    material_process_matProcessing_supplier_name = 'material_process_matProcessing_supplier_name',
    material_process_rmatProcessing_supplier_names = 'material_process_rmatProcessing_supplier_names',
    material_process_extraction_supplier_names = 'material_process_extraction_supplier_names',
    material_certificate_names = 'material_certificate_names',

    Traceability_Warehouse_Origin = 'Traceability_Warehouse_Origin',
    Traceability_Manufacturing_Transport = 'Traceability_Manufacturing_Transport',
    Traceability_Manufacturing_Origin = 'Traceability_Manufacturing_Origin',
    Traceability_Primary_Retail = 'Traceability_Primary_Retail',
    Traceability_Secondary_Warehouse = 'Traceability_Secondary_Warehouse',
    Traceability_Terciary_Shipping = 'Traceability_Terciary_Shipping',

    projectName = 'projectName',
    contArticle = 'contArticle',
    referenceType = 'referenceType',
    dataCollectionURL = 'dataCollectionURL',
    status = 'status',
    createdAt = 'createdAt',
    closingDate = 'closingDate',
    folderURL = 'folderURL',
    MCI_A = "MCI_A",
    Recycled_A = "Recycled_A",
    TU_Waste_A = "TU_Waste_A",
    Utility_A = "Utility_A",
    saved = 'saved',
}

export enum EEquivalencesKeyNames {
  glasses = 'glasses',
  trashBags = 'trashBags',
  showers = 'showers',
  carbonApsortion = 'carbonApsortion',
  phoneCall = 'phoneCall',
  cheeseburgers = 'cheeseburgers',
  coffeeCups = 'coffeeCups',
  ligthBulbs = 'ligthBulbs',
  smartphoneCharge = 'smartphoneCharge',
  emails = 'emails',
  spanishTomatoes = 'spanishTomatoes',
  gasoline = 'gasoline',
  bottles = 'bottles',
  socks = 'socks',
  sushis = 'sushis',
  gasConsumption = 'gasConsumption',
  danishTomatoes = 'danishTomatoes',
  hairDrying = 'hairDrying',
  petBottlesSmall = 'petBottlesSmall',
  carRefuel = 'carRefuel',
  laundry = 'laundry',
  diesel = 'diesel',
  petBottlesMid = 'petBottlesMid',

  pool = 'pool',

  bottle = 'bottle',
  lightBulbs = 'ligthBulbs',
  washingMachine = 'washingMachine',
  carTravelling = 'carTravelling',

  olympicPools = 'olympicPools',
  electricity = 'electricity',
  detergent = 'detergent',
  planeTravelling = 'planeTravelling',

  waterIntake = 'waterIntake',
  tree = 'tree',
  fish = 'fish',
  carNotTravelled = 'carNotTravelled',

  waterIntakeYear = 'waterIntakeYear',
  plantedTrees = 'plantedTrees',
  oxygen = 'oxygen',
  divingCylinder = 'divingCylinder',
  planeNotTravelled = 'planeNotTravelled',
}